// src/pages/Category.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Content from '../components/Category';

function Category() {
    return (
        <>
            <Header />
            <Content />
            <Footer />
        </>
    );
}

export default Category;
