// src/pages/CategoryPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const PostCard = ({ post }) => (
    <div className="card border-0 mb-5 box-shadow">
        <div style={{ backgroundImage: `url(${post.featured_image})`, height: '350px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
        <div className="card-body px-0 pb-0 d-flex flex-column align-items-start">
            <h2 className="h2 font-weight-bold">
                <a className="text-dark" href={post.link}>{post.title}</a>
            </h2>
            <p className="card-text">{post.excerpt}</p>
            <div>
                <small className="d-block"><a className="text-muted" href={`/author/${post.author}`}>{post.author}</a></small>
                <small className="text-muted">{post.date} · {post.reading_time} read</small>
            </div>
        </div>
    </div>
);

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
};

const LatestPosts = ({ posts }) => {
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768; // Define your mobile breakpoint here

    return (
        <div>
            {posts.map(post => (
                <div key={post.id} style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    marginBottom: '1rem',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <div style={{ flex: 1 }}>
                        <h2 className="mb-1 h4 font-weight-bold">
                            <a className="text-dark" href={post.link}>{post.title}</a>
                        </h2>
                        <p>{post.excerpt}</p>
                        <div className="card-text text-muted small">{post.author}</div>
                        <small className="text-muted">{post.date} · {post.reading_time} read</small>
                    </div>
                    <img 
                        src={post.featured_image} 
                        alt={post.title} 
                        style={{
                            width: isMobile ? '100%' : '213px',
                            height: 'auto',
                            objectFit: 'cover'
                        }} 
                    />
                </div>
            ))}
        </div>
    );
};

const Sidebar = ({ popularPosts }) => (
    <div className="sticky-top">
        <h5 className="font-weight-bold spanborder"><span>Popular in Science</span></h5>
        <ol className="list-featured">
            {popularPosts.map(post => (
                <li key={post.id}>
                    <span>
                        <h6 className="font-weight-bold">
                            <a href={post.link} className="text-dark">{post.title}</a>
                        </h6>
                        <p className="text-muted">{post.author}</p>
                    </span>
                </li>
            ))}
        </ol>
    </div>
);

const SubscriptionForm = () => (
    <div className="container pt-4 pb-4">
        <div className="border p-5 bg-lightblue">
            <div className="row justify-content-between">
                <div className="col-md-6">
                    <h5 className="font-weight-bold secondfont">Become a member</h5>
                    Get the latest news right in your inbox. It's free and you can unsubscribe at any time. We hate spam as much as we do, so we never spam!
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-6">
                            <input type="text" className="form-control" placeholder="First name" />
                        </div>
                        <div className="col-md-6">
                            <input type="text" className="form-control" placeholder="Last name" />
                        </div>
                        <div className="col-md-12 mt-3">
                            <button type="submit" className="btn btn-success btn-block">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const CategoryPage = () => {
    const { slug } = useParams();
    const [posts, setPosts] = useState([]);
    const [popularPosts, setPopularPosts] = useState([]);
    const [category, setCategory] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPosts = async () => {
            if (loading || !hasMore) return;

            setLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/custom/v1/posts-by-category/${slug}?page=${page}`);
                setPosts(prevPosts => [
                    ...prevPosts,
                    ...data.posts
                ]);
                setCategory(data.category);
                setHasMore(page < data.total_pages);
            } catch (error) {
                console.error('Error fetching posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, [page]);

    useEffect(() => {
        const fetchPopularPosts = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/custom/v1/popular-posts`);
                setPopularPosts(data.posts);
            } catch (error) {
                console.error('Error fetching popular posts:', error);
            }
        };

        fetchPopularPosts();
    }, []);

    useEffect(() => {
        const handleScroll = (e) => {
            const { scrollTop, scrollHeight, clientHeight } = e.target.documentElement;
            if (scrollHeight - scrollTop <= clientHeight * 1.5 && hasMore && !loading) {
                setPage(prevPage => prevPage + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasMore, loading]);

    return (
        <>
            <Helmet>
                <title>{category ? `${category.name} - cricfeed` : 'Category - cricfeed'}</title>
                <meta name="description" content={category ? category.description : 'Explore the latest posts in this category.'} />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "WebPage",
                        "name": category ? `${category.name} - cricfeed` : 'Category - cricfeed',
                        "description": category ? category.description : 'Explore the latest posts in this category.',
                        "url": window.location.href,
                        "mainEntity": {
                            "@type": "WebPage",
                            "name": category ? `${category.name} - cricfeed` : 'Category - cricfeed',
                            "url": window.location.href
                        }
                    })}
                </script>
            </Helmet>
            <div className="container mt-6 mb-5">
                <div className="row">
                    <div className="col-md-8">
                        <h5 className="font-weight-bold spanborder"><span>Featured in {category ? category.name : 'Category'}</span></h5>
                        {posts.length > 0 && <PostCard post={posts[0]} />}
                        <h5 className="font-weight-bold spanborder"><span>Latest</span></h5>
                        <LatestPosts posts={posts.slice(1, 10)} />
                    </div>
                    <div className="col-md-4 pl-4">
                        <Sidebar popularPosts={popularPosts} />
                    </div>
                </div>
            </div>
            <SubscriptionForm />
        </>
    );
};

export default CategoryPage;
