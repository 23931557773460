// src/components/Footer.js
import React from 'react';

function Footer() {
    return (
        <div className="container mt-5">
            <footer className="bg-white border-top p-3 text-muted small">
                <div className="row align-items-center justify-content-between">
                    <div>
                        <span className="navbar-brand mr-2"><strong>cricfeed.live</strong></span> Copyright &copy; 
                        <script>document.write(new Date().getFullYear())</script> 
                        . All rights reserved.
                    </div>
                    <div>
						<ul className="footer-menu">
							<li>
								<a href="/about-us/">About us</a>
							</li>
							<li>
								<a href="/contact-us/">Contact us</a>
							</li>
							<li>
								<a href="/disclaimer/">Disclaimer</a>
							</li>
							<li>
								<a href="/dmca-policy/">DMCA Policy</a>
							</li>
							<li>
								<a href="/privacy-policy/">Privacy Policy</a>
							</li>
							<li>
								<a href="/terms-conditions/">Terms & condition</a>
							</li>
						</ul>
					</div>
                </div>
            </footer>
        </div>
		
    );
}

export default Footer;
