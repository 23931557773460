// src/pages/HomePage.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Content from '../components/HomeContent';

function HomePage() {
    return (
			<>
            <Header />
            <Content />
            <Footer />
			</>
    );
}

export default HomePage;
