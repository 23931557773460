import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const PostDetail = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const post_type = post?.post_type;

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/custom/v1/post/${slug}`);
                setPost(response.data);
            } catch (err) {
                setError(err);
				} finally {
                setLoading(false);
            }
        };

        fetchPost();
    }, [slug]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading post.</div>;
    if (!post) return <div>Post not found.</div>;

    if (post_type === 'post') {
        const jsonLd = {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": post.title,
            "description": post.excerpt,
            "image": post.featured_image,
            "author": {
                "@type": "Person",
                "name": post.author.name,
                "image": post.author.avatar
            },
            "datePublished": post.date,
            "dateModified": post.date,
            "publisher": {
                "@type": "Organization",
                "name": "cricfeed.live",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.cricfeed.live/api/wp-content/uploads/2024/08/logo.svg?w=300"
                }
            },
            "url": `${window.location.origin}/post/${slug}`
        };

        return (
            <div className="container mt-6 mb-5">
                <Helmet>
                    <title>{post.title} - cricfeed</title>
                    <meta name="description" content={post.excerpt} />
                    <meta name="keywords" content={post.keyword} />
                    <link rel="canonical" href={`${window.location.origin}/post/${slug}`} />
                    <script type="application/ld+json">
                        {JSON.stringify(jsonLd)}
                    </script>
                </Helmet>
                {/* Header */}
                <div className="jumbotron jumbotron-fluid mb-3 pl-0 pt-0 pb-0 bg-white position-relative">
                    <div className="h-100 tofront">
                        <div className="row justify-content-between">
                            <div className="col-md-6 pt-6 pb-6 align-self-center">
                                <p className="text-uppercase font-weight-bold">
                                    <a className="text-danger" href="./category.html">Stories</a>
                                </p>
                                <h1 className="secondfont mb-3 font-weight-bold">
                                    {post.title}
                                </h1>
                                <p className="mb-3">
                                    {post.excerpt}
                                </p>
                                <div className="d-flex align-items-center">
                                    <img className="rounded-circle" src={post.author.avatar || 'assets/img/demo/avatar2.jpg'} width="70" alt={post.author.name} />
									
                                    <small className="ml-2">
                                        {post.author.name}
                                        <span className="text-muted d-block">
                                            {new Date(post.date).toLocaleDateString()} &middot; {post.reading_time} min. read
                                        </span>
                                    </small>
                                </div>
                            </div>
                            <div className="col-md-6 pt-6 pb-6 pr-6 align-self-top">
                                {post.featured_image && (
									<>
										<img
											src={post.featured_image}
											srcSet={`${post.featured_image}?w=300 300w, ${post.featured_image}?w=600 600w, ${post.featured_image}?w=1200 1200w`}
											sizes="(max-width: 576px) 100vw, (max-width: 768px) 50vw, 33vw"
											alt={post.title}
											style={{ width: '100%', height: 'auto' }}
										/>
										<em style={{
											fontStyle: 'italic',
											fontSize: '12px'
										}}>Image Source: {post.image_source}</em>
									</>
								)}

                            </div>
                        </div>
                    </div>
                </div>
                {/* End Header */}
                
                {/* Main Content */}
                <div className="container pt-4 pb-4">
                    <div className="row justify-content-center">
                        <div className="col-lg-2 pr-4 mb-4 col-md-12">
                            <div className="sticky-top text-center">
                                <div className="text-muted">
                                    Share this
                                </div>
                                <div className="share d-inline-block">
                                    {/* AddToAny BEGIN */}
                                    <div className="a2a_kit a2a_kit_size_32 a2a_default_style">
                                        <a className="a2a_dd" href="https://www.addtoany.com/share"></a>
                                        <a className="a2a_button_facebook"></a>
                                        <a className="a2a_button_twitter"></a>
                                    </div>
                                    <script async src="https://static.addtoany.com/menu/page.js"></script>
                                    {/* AddToAny END */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <article className="article-post">
                                <div dangerouslySetInnerHTML={{ __html: post.content }} />
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (post_type === 'page') {
        const jsonLd = {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": document.title,
            "description": document.querySelector('meta[name="description"]').getAttribute('content'),
            "url": window.location.href,
            "publisher": {
                "@type": "Organization",
                "name": "cricfeed.live",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.cricfeed.live/api/wp-content/uploads/2024/08/logo.svg?w=300"
                }
            },
            "mainEntity": {
                "@type": "WebPageElement",
                "name": "Main Content",
                "description": document.querySelector('meta[name="description"]').getAttribute('content')
            }
        };

        return (
            <div className="container mt-6 mb-5">
                <Helmet>
                    <title>{post.title} - cricfeed</title>
                    <meta name="description" content={post.excerpt} />
                    <meta name="keywords" content={post.keyword} />
                    <link rel="canonical" href={`${window.location.origin}/post/${slug}`} />
                    <script type="application/ld+json">
                        {JSON.stringify(jsonLd)}
                    </script>
                </Helmet>
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xs-12">
                        <h1 className="secondfont mb-3 font-weight-bold">
                            {post.title}
                        </h1>
                        <article className="article-post">
                            <div dangerouslySetInnerHTML={{ __html: post.content }} />
                        </article>
                    </div>
                </div>
            </div>
        );
    }

    return null; // In case post_type is neither 'post' nor 'page'
};

export default PostDetail;
