// src/components/Skeleton.js
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = ({ type }) => {
    switch (type) {
        case 'header':
            return (
                <nav className="topnav navbar navbar-expand-lg navbar-light bg-white fixed-top">
                    <div className="container">
                        <div className="navbar-brand">
                            <Skeleton height={50} width={200} />
                        </div>
                    </div>
                </nav>
            );
        case 'content':
            return (
                <>
                    <header className="container mt-6 mb-5" role="banner">
                        <div className="jumbotron jumbotron-fluid mb-3 pt-0 pb-0 bg-lightblue position-relative">
                            <div className="pl-4 pr-0 h-100 tofront">
                                <div className="row justify-content-between">
                                    <div className="col-md-6 pt-6 pb-6 align-self-center">
                                        <Skeleton height={200} width="100%" />
                                        <Skeleton count={3} height={20} style={{ marginTop: 10 }} />
                                    </div>
                                    <div className="col-md-6 d-none d-md-block pr-0">
                                        <Skeleton height={500} width="100%" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <main className="container pt-4 pb-4" role="main">
                        <div className="row">
                            <article className="col-lg-6 mb-4 d-flex flex-column">
                                <div className="card border-0 box-shadow flex-grow-1" style={{ height: 450 }}>
                                    <Skeleton height={380} width="100%" />
                                    <Skeleton height={20} width="100%" />
                                    <Skeleton height={20} width="80%" />
                                    <Skeleton height={10} width="100%" />
                                    <Skeleton height={10} width="90%" />
                                    <Skeleton height={10} width="80%" />
                                </div>
                            </article>
                            <section className="col-lg-6">
                                <div className="d-flex flex-column">
                                    
                                        <article  className="mb-3 d-flex align-items-center">
                                            <div className="lazyload-wrapper" style={{ width: 120 }}>
                                                <Skeleton height={120} width="100%" />
                                            </div>
                                            <div className="pl-3" style={{ width: '100%' }}>
                                                <Skeleton height={20} width="100%" />
                                                <Skeleton height={20} width="80%" />
                                                <Skeleton height={10} width="100%" />
                                                <Skeleton height={10} width="90%" />
                                            </div>
                                        </article>
										<article  className="mb-3 d-flex align-items-center">
                                            <div className="lazyload-wrapper" style={{ width: 120 }}>
                                                <Skeleton height={120} width="100%" />
                                            </div>
                                            <div className="pl-3" style={{ width: '100%' }}>
                                                <Skeleton height={20} width="100%" />
                                                <Skeleton height={20} width="80%" />
                                                <Skeleton height={10} width="100%" />
                                                <Skeleton height={10} width="90%" />
                                            </div>
                                        </article>
										<article  className="mb-3 d-flex align-items-center">
                                            <div className="lazyload-wrapper" style={{ width: 120 }}>
                                                <Skeleton height={120} width="100%" />
                                            </div>
                                            <div className="pl-3" style={{ width: '100%' }}>
                                                <Skeleton height={20} width="100%" />
                                                <Skeleton height={20} width="80%" />
                                                <Skeleton height={10} width="100%" />
                                                <Skeleton height={10} width="90%" />
                                            </div>
                                        </article>
                                </div>
                            </section>
                        </div>
                    </main>
					<section className="container">
						<div className="row justify-content-between">
							<div className="col-md-8 col-xs-12 col-sm-8 col-lg-8">
							<h5 className="font-weight-bold spanborder"><span><Skeleton height={20} width="20%" /></span></h5>
								
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								
							</div>
							<div className="col-md-4 col-sm-4 col-xs-12 col-lg-4">
								<h5 className="font-weight-bold spanborder"><span><Skeleton height={20} width="20%" /></span></h5>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
								<article  className="mb-3 d-flex align-items-center">
									<div className="lazyload-wrapper" style={{ width: 120 }}>
										<Skeleton height={120} width="100%" />
									</div>
									<div className="pl-3" style={{ width: '100%' }}>
										<Skeleton height={20} width="100%" />
										<Skeleton height={20} width="80%" />
										<Skeleton height={10} width="100%" />
										<Skeleton height={10} width="90%" />
									</div>
								</article>
							</div>
						</div>
					</section>	
					
                </>
            );
        default:
            return <Skeleton />;
    }
};

export default SkeletonLoader;
