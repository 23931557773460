// src/components/Header.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SkeletonLoader from './Skeleton';



function Header() {
    const [menuItems, setMenuItems] = useState([]);
    const [siteSettings, setSiteSettings] = useState({
        logo: '',
        favicon: '',
        site_title: '',
        site_description: ''
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch both menu items and site settings
        const fetchMenuItems = axios.get('https://www.cricfeed.live/wp-content/uploads/apis/menu.json');
        const fetchSiteSettings = axios.get('https://www.cricfeed.live/wp-content/uploads/apis/site-settings.json');

        // Wait for both requests to complete
        Promise.all([fetchMenuItems, fetchSiteSettings])
            .then(([menuResponse, settingsResponse]) => {
                setMenuItems(menuResponse.data);
                setSiteSettings(settingsResponse.data);

                // Update favicon and page title
                document.title = settingsResponse.data.site_title;
                const faviconLink = document.querySelector("link[rel*='icon']");
                if (faviconLink) {
                    faviconLink.href = settingsResponse.data.favicon;
                } else {
                    const link = document.createElement('link');
                    link.rel = 'icon';
                    link.href = settingsResponse.data.favicon;
                    document.head.appendChild(link);
                }

                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <SkeletonLoader type="header" />;
    }

    return (
        <nav className="topnav navbar navbar-expand-lg navbar-light bg-white fixed-top">
            <div className="container">
                <a className="navbar-brand" href="/">
				<img 
                        src={siteSettings.logo}
                        srcSet={`${siteSettings.logo}?w=300 213w,
                                ${siteSettings.logo}?w=600 213w,
                                ${siteSettings.logo}?w=1200 213w`}
                        sizes="(max-width: 576px) 100px, 
                               (max-width: 768px) 150px, 
                               200px"
                        alt={siteSettings.site_title} 
                        className="img-fluid web-logo"
						width="213"
                        height="40"
                    />
                    
                </a>
                <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-collapse collapse" id="navbarColor02">
                    <ul className="navbar-nav mr-auto d-flex align-items-center">
                        {menuItems.map(item => (
                            <li key={item.ID} className="nav-item">
                                <a className="nav-link" href={`/category/${item.slug}`}>{item.title}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Header;
