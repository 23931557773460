// src/pages/PostDetail.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Content from '../components/PostDetail';

function PostDetail() {
    return (
        <div>
            <Header />
            <Content />
            <Footer />
        </div>
    );
}

export default PostDetail;
