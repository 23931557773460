// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';
import PostDetail from './pages/PostDetail';
import Category from './pages/Category';
import './App.css';
import './styles/styles.css';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/:slug" element={<PostDetail />} />
                    <Route path="/category/:slug" element={<Category />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
