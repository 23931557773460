import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import SkeletonLoader from './Skeleton';
import LazyLoad from 'react-lazyload';
import { Helmet } from 'react-helmet';

const resizeImage = (file, width, height) => {
    return new Promise((resolve, reject) => {
        Resizer.imageFileResizer(
            file,
            width,
            height,
            'JPEG',
            100,
            0,
            uri => {
                resolve({ uri, width, height });
            },
            'base64'
        );
    });
};

function Content() {
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [resizedImages, setResizedImages] = useState({});
    const sideContentRef = useRef(null);
    const imgRefs = useRef([]);

    const url = 'https://www.cricfeed.live/wp-content/uploads/apis/recent-posts.json';

    useEffect(() => {
        axios.get(url)
            .then(async response => {
                const posts = response.data;

                const resizedImages = {};
                if (posts.length > 0) {
                    resizedImages.firstPostImage = await resizeImage(await fetchImageBlob(posts[0].featured_image), 800, 500);
                }
                if (posts.length > 1) {
                    resizedImages.secondPostImage = await resizeImage(await fetchImageBlob(posts[1].featured_image), 600, 150);
                }
                if (posts.length > 2) {
                    for (let i = 2; i < Math.min(posts.length, 5); i++) {
                        resizedImages[`thirdPostImage${i - 2}`] = await resizeImage(await fetchImageBlob(posts[i].featured_image), 120, 93);
                    }
                }
                if (posts.length > 5) {
                    for (let i = 5; i < Math.min(posts.length, 10); i++) {
                        resizedImages[`remainingPostImage${i - 5}`] = await resizeImage(await fetchImageBlob(posts[i].featured_image), 120, 93);
                    }
                }

                setContent(posts);
                setResizedImages(resizedImages);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the content!', error);
                setLoading(false);
            });
    }, []);

    const fetchImageBlob = async (imageUrl) => {
        const response = await fetch(imageUrl);
        return await response.blob();
    };

    useEffect(() => {
        const updateImageHeight = () => {
            const equalHeightElements = document.querySelectorAll('.equal_height');
            equalHeightElements.forEach((el, index) => {
                const height = el.clientHeight;
                if (imgRefs.current[index]) {
                    imgRefs.current[index].style.height = `${height}px`;
                }
            });
        };

        updateImageHeight();
        window.addEventListener('resize', updateImageHeight);
        return () => window.removeEventListener('resize', updateImageHeight);
    }, [content]);

    if (loading) {
        return <SkeletonLoader type="content" />;
    }

    const firstPost = content && content[0];
    const secondPost = content && content[1];
    const thirdPosts = content && content.slice(2, 5);
    const remainingPosts = content && content.slice(5, 10);

    return (
        <>
            <Helmet>
                <title>Cricket Feed - Where Cricket Stories Come Alive</title>
                <meta name="description" content="Get latest news, latest updates, live cricket scorecard, player profile, ICC rankings & many more only on cricfeed.live." />
                <meta property="og:title" content="Cricket Feed - Where Cricket Stories Come Alive" />
                <meta property="og:description" content="Get latest news, latest updates, live cricket scorecard, player profile, ICC rankings & many more only on cricfeed.live." />
                <meta property="og:image" content={resizedImages.firstPostImage?.uri || 'default-image-url'} />
                <meta property="og:url" content={window.location.href} />
                <link rel="canonical" href={window.location.href} />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Cricket Feed - Where Cricket Stories Come Alive",
                        "description": "Get latest news, latest updates, live cricket scorecard, player profile, ICC rankings & many more only on cricfeed.live.",
                        "publisher": {
                            "@type": "Organization",
                            "name": "cricfeed.live"
                        }
                    })}
                </script>
            </Helmet>

            {/* Header Section */}
            {firstPost && (
                <header className="container mt-6 mb-5" role="banner">
                    <div className="jumbotron jumbotron-fluid mb-3 pt-0 pb-0 bg-lightblue position-relative">
                        <div className="pl-4 pr-0 h-100 tofront">
                            <div className="row justify-content-between">
                                <div className="col-md-6 pt-6 pb-6 align-self-center" ref={sideContentRef}>
                                    <h1 className="secondfont mb-3 font-weight-bold">{firstPost.title}</h1>
                                    <p className="mb-3">{firstPost.excerpt}</p>
                                    <a href={`/${firstPost.slug}`} className="btn btn-dark" aria-label={`Read more about ${firstPost.title}`}>{firstPost.title}</a>
                                </div>
                                <div
                                    className="col-md-6 d-none d-md-block pr-0"
                                    style={{
                                        backgroundSize: 'cover',
                                        backgroundImage: `url(${resizedImages.firstPostImage?.uri})`,
                                        height: '500px', // Explicit height set here
                                        width: '800px' // Explicit width set here
                                    }}
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            )}

            {/* Main Content Section */}
            <main className="container pt-4 pb-4" role="main">
                <div className="row">
                    {secondPost && (
                        <article className="col-lg-6 mb-4 d-flex flex-column">
                            <div className="card border-0 box-shadow flex-grow-1">
                                <LazyLoad height={150} offset={100}>
                                    <img
                                        srcSet={`${resizedImages.secondPostImage?.uri} 600w, ${resizedImages.secondPostImage?.uri} 800w`}
                                        sizes="(max-width: 576px) 100vw, (max-width: 768px) 50vw, 50vw"
                                        src={resizedImages.secondPostImage?.uri}
                                        alt={secondPost.title}
                                        className="img-fluid min-100-per"
                                        width="600"
                                        height="150"
                                        ref={el => imgRefs.current[1] = el}
                                    />
                                </LazyLoad>
                                <div className="card-body px-0 pb-0 d-flex flex-column align-items-start">
                                    <h2 className="h4 font-weight-bold">
                                        <a className="text-dark" href={`/${secondPost.slug}`}>{secondPost.title}</a>
                                    </h2>
                                    <p className="card-text">{secondPost.excerpt}</p>
                                    <div>
                                        <small className="d-block">
                                            <a className="text-muted" href={`/web-author/${secondPost.author_slug}`}>{secondPost.news_author.name || 'Unknown Author'}</a>
                                        </small>
                                        <small className="text-muted">
                                            {secondPost.date ? new Date(secondPost.date).toLocaleDateString() : 'Unknown Date'} &middot; {secondPost.reading_time ? `${secondPost.reading_time} min read` : 'Unknown Reading Time'}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </article>
                    )}

                    {/* Third Posts Section */}
                    <section className="col-lg-6">
                        <div className="d-flex flex-column">
                            {thirdPosts && thirdPosts.map((post, index) => (
                                <article className="mb-3 d-flex align-items-center" key={post.post_id} ref={el => imgRefs.current[2 + index] = el}>
                                    <LazyLoad height={93} offset={100}>
                                        <img
                                            srcSet={`${resizedImages[`thirdPostImage${index}`]?.uri} 120w, ${resizedImages[`thirdPostImage${index}`]?.uri} 180w`}
                                            sizes="(max-width: 576px) 100px, 120px"
                                            src={resizedImages[`thirdPostImage${index}`]?.uri}
                                            alt={post.title}
                                            className="image-resize min-120"
                                            width="120"
                                            height="93"
                                            ref={el => imgRefs.current[2 + index] = el}
                                        />
                                    </LazyLoad>
                                    <div className="pl-3">
                                        <h2 className="mb-2 h6 font-weight-bold">
                                            <a className="text-dark" href={`/${post.slug}`}>{post.title}</a>
                                        </h2>
                                        <div className="card-text text-muted small">
                                            {post.news_author.name || 'Unknown Author'}
                                        </div>
                                        <small className="text-muted">
                                            {post.date ? post.date : 'Unknown Date'} &middot; {post.reading_time ? `${post.reading_time} min read` : 'Unknown Reading Time'}
                                        </small>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </section>
                </div>

                {/* Remaining Posts */}
                <section className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-8">
                            <h5 className="font-weight-bold spanborder"><span>All Stories</span></h5>
                            {remainingPosts && remainingPosts.map((post, index) => (
                                <article className="mb-3 d-flex align-items-center" key={post.post_id} ref={el => imgRefs.current[10 + index] = el}>
                                    <LazyLoad height={93} offset={100}>
                                        <img
                                            srcSet={`${resizedImages[`remainingPostImage${index}`]?.uri} 120w, ${resizedImages[`remainingPostImage${index}`]?.uri} 180w`}
                                            sizes="(max-width: 576px) 100px, 120px"
                                            src={resizedImages[`remainingPostImage${index}`]?.uri}
                                            alt={post.title}
                                            className="image-resize min-120"
                                            width="120"
                                            height="93"
                                            ref={el => imgRefs.current[10 + index] = el}
                                        />
                                    </LazyLoad>
                                    <div className="pl-3">
                                        <h2 className="mb-2 h6 font-weight-bold">
                                            <a className="text-dark" href={`/${post.slug}`}>{post.title}</a>
                                        </h2>
                                        <div className="card-text text-muted small">
                                            {post.news_author.name || 'Unknown Author'}
                                        </div>
                                        <small className="text-muted">
                                            {post.date ? post.date : 'Unknown Date'} &middot; {post.reading_time ? `${post.reading_time} min read` : 'Unknown Reading Time'}
                                        </small>
                                    </div>
                                </article>
                            ))}
                        </div>
                        <div className="col-md-4">
                            {/* Additional content can be added here */}
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Content;
